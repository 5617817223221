import axios from "axios";

export function getUploadImageAction() {
    return axios.defaults.baseURL + "upload/image"
}
export function upload (parameter) {
    return axios({
        url: "upload/image",
        method: 'post',
        data: parameter
    })
}
