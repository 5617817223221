import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/salesman/page", parms)
}
export function fetchForm(id) {
    return axios.get("/salesman/details", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/salesman/save", entity)
}

export function remove(id) {
    return axios.post("/salesman/delete", {id})
}
