import axios from "axios";

export function fetchOperationLogList(queryParam) {
    return axios.post("/operationLog/page", queryParam)
}

export function fetchOperationLogDetails(id) {
    return axios.get("/operationLog/details", {
        params: {id}
    })
}
