

import {getValidateCode, login} from "@/api/login";
import {ref, toRaw} from "vue";
import {useRouter} from "vue-router";
import {NotifyPlugin} from 'tdesign-vue-next'

const rules = {
    username: [{required: true, message: '请输入用户名', type: 'error'}],
    password: [{required: true, message: '请输入密码', type: 'error'}],
    code: [{required: true, message: '请输入验证码', type: 'error'}],
};
let redirectUrl = "/news"
export default ({
    name: "Login",
    setup() {
        const codeSrc = ref(getValidateCode());
        const router = useRouter()
        const formState = ref({
            username: '',
            password: '',
            code:''
        })

        const onSubmit = ({validateResult, firstError}: any) => {

            console.log(validateResult);
            console.log(firstError);

            login(toRaw(formState.value)).then(function (res: any) {
                if (res.data.code == 1) {
                    //router.back();
                    router.push(redirectUrl);
                } else {
                    NotifyPlugin('error', {title: '登录失败', content: res.data.msg, duration: 2000})
                }
                refreshCode();
            })
        }
        const refreshCode = ()=>{
            codeSrc.value = getValidateCode()
        }

        return {
            codeSrc,
            formState,
            rules,
            onSubmit,
            refreshCode
        }
    },
    beforeRouteEnter(to:any, form:any){
        if(form.fullPath != "/"){
            redirectUrl = form.fullPath;
        }
        console.log(form);
    }
});


