import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/solution/page", parms)
}
export function fetchForm(id) {
    return axios.get("/solution/form", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/solution/save", entity)
}
export function recycle(id) {
    return axios.post("/solution/recycle", {id})
}
export function recover(id) {
    return axios.post("/solution/recover", {id})
}
export function remove(id) {
    return axios.post("/solution/delete", {id})
}
