export const COLUMNS = [

    {
        title: '名称',
        colKey: 'name',
    },
    {
        title: '分类',
        colKey: 'classifyName',
    },
    {
        title: '删除时间',
        colKey: 'updatedTime',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
