import axios from "axios";

export function fetchCasePage(parms) {
    return axios.post("/case/page", parms)
}
export function fetchCaseList(parms) {
    return axios.post("/case/list", parms)
}
export function fetchCaseForm(id) {
    return axios.get("/case/form", {
        params: {id}
    })
}

export function saveCase(entity) {
    return axios.post("/case/save", entity)
}

export function recycleCase(id) {
    return axios.post("/case/recycle", {id})
}
export function recoverCase(id) {
    return axios.post("/case/recover", {id})
}
export function deleteCase(id) {
    return axios.post("/case/delete", {id})
}
