export const COLUMNS = [
    {
        title: '名称',
        colKey: 'name',
    },
    {
        title: '图标',
        colKey: 'icon',
        width: 70
    },
    {
        title: '路由',
        colKey: 'router',
    },
    {
        title: '权限',
        colKey: 'perms',
    },
    {
        title: '类型',
        colKey: 'type',
    },
    {
        title: '排序',
        colKey: 'orderNum',
    },
    {
        title: '操作',
        colKey: 'operation',
        minWidth: 220,
        width: 220
    },
];
export const RULES = {
    name: [
        { required: true, message: '名称必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    remark: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
