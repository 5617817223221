import axios from "axios";

export function fetchRolePage(queryParam) {
    return axios.post("/role/page", queryParam)
}
export function fetchRoleList() {
    return axios.get("/role/list")
}
export function fetchRoleDetails(id) {
    return axios.get("/role/details", {
        params: {id}
    })
}
export function fetchRoleForm(id) {
    return axios.get("/role/form", {
        params: {id}
    })
}
export function saveRole(entity) {
    return axios.post("/role/save", entity)
}
