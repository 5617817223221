import axios from "axios";

export function fetchClassifyPage(parms) {
    return axios.post("/solutionClassify/page", parms)
}
export function fetchClassifyList(parms) {
    return axios.post("/solutionClassify/list", parms)
}
export function fetchClassifyForm(id) {
    return axios.get("/solutionClassify/form", {
        params: {id}
    })
}

export function saveClassify(entity) {
    return axios.post("/solutionClassify/save", entity)
}

export function deleteClassify(id) {
    return axios.post("/solutionClassify/delete", {id})
}
