import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/visit/page", parms)
}
export function fetchForm(id) {
    return axios.get("/visit/details", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/visit/save", entity)
}
export function remove(id) {
    return axios.post("/visit/delete", {id})
}
