<template>
    <Editor
        api-key="a2w4axxkq5lfxhgrejilqbdk87s3c487evgxa5i5hg86zz3i"
        v-model="text"
        :init="editorInit"
    ></Editor>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {computed, watch} from "vue";
import {upload} from "@/api/upload";
const serverName = ""
export default {
    name: 'MyEditor',
    components: {
        Editor
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        plugins: {
            type: [String, Array],
            default: 'lists image media table wordcount fullscreen link codesample code uploadVideo uploadMedia'
        },
        toolbar: {
            type: [String, Array],
            default: 'uploadVideo | uploadMedia | codesample | code | searchreplace | undo redo |  formatselect | fontsizeselect  | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table  | removeformat | paste-upload | fullscreen | link | preview '
        }
    },


    setup(props, context){

        const id = 'tinymce';
        const editorInit = {
            language_url: '/tinymce/langs/zh_CN.js',
                language: 'zh_CN',
                skin_url: '/tinymce/skins/ui/oxide', // 如果主题不存在，指定一个主题路径
                content_css: '/tinymce/skins/content/default/content.css',
                width: '100%',
                height: 800,
                plugins: props.plugins, // 插件
                toolbar: props.toolbar, // 工具栏
                branding: false, // 技术支持(Powered by Tiny || 由Tiny驱动)
                menubar: true, // 菜单栏
                theme: 'silver', // 主题
                images_upload_url: process.env.VUE_APP_API_BASE_URL + serverName + '/resource/uploadFile', // 图片上传路径
                images_upload_credentials: true,
                images_upload_base_path: '', // 如果图片上传路径是相对路径，则指定一个基本路径
                images_upload_handler: uploadImgFile,
                zIndex: 1101,
                setup (editor) {
                editor;
                // editor.ui.registry.addButton('uploadVideo', {
                //     text: '本地视频',
                //     // 是否显示图标
                //     icon: 'non-breaking',
                //     onAction: () => {
                //         that.$refs['uploader_' + that.tinymceCode].openUpload('video/*')
                //     }
                // })
                // editor.ui.registry.addButton('uploadMedia', {
                //     text: '素材中心',
                //     // 是否显示图标
                //     icon: 'browse',
                //     onAction: () => {
                //         that.$refs['mediaCenter_' + that.tinymceCode].openMedia()
                //     }
                // })
            }
        }

        const text = computed({
            get(){ return props.value },
            set(val){changeValue(val)}
        })

        watch(text, () => {
            //changeValue();
        });

        const changeValue = (val)=>{
            console.log(val);
            context.emit("update:value", val)
        }

        return{
            id,
            editorInit,
            text,
            changeValue,
        }
    },
}


const uploadImgFile = (blobInfo, success) => {
    const fd = new FormData()
    fd.append('file', blobInfo.blob())
    console.log("upload:" );
    console.log(blobInfo.blob());
    upload(fd)
        .then(({data}) => {
            if(data.code == 200){
                const {url} = data.data;
                success(url);
            }
        })
        .catch(err => {
            console.log(err)
        })
}
</script>

<style scoped>

</style>
