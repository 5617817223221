import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/user/page", parms)
}
export function fetchForm(id) {
    return axios.get("/user/details", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/user/save", entity)
}
export function remove(id) {
    return axios.post("/user/delete", {id})
}
