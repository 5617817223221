import axios from 'axios'

export function login({username, password, code}) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('code', code);
    return axios.post("/login", params);
}

export function logout(){
    return axios.post("/logout")
}

export function getValidateCode(){
    return axios.defaults.baseURL + "/validateCode/image?" + new Date().getTime()
}
