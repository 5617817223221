import axios from "axios";

export function fetchNewsPage(parms) {
    return axios.post("/news/page", parms)
}
export function fetchNewsList(parms) {
    return axios.post("/news/list", parms)
}
export function fetchNewsForm(id) {
    return axios.get("/news/form", {
        params: {id}
    })
}

export function saveNews(entity) {
    return axios.post("/news/save", entity)
}

export function recycleNews(id) {
    return axios.post("/news/recycle", {id})
}
export function recoverNews(id) {
    return axios.post("/news/recover", {id})
}
export function deleteNews(id) {
    return axios.post("/news/delete", {id})
}
