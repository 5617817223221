import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/serve/page", parms)
}
export function fetchForm(id) {
    return axios.get("/serve/form", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/serve/save", entity)
}
export function recycle(id) {
    return axios.post("/serve/recycle", {id})
}
export function recover(id) {
    return axios.post("/serve/recover", {id})
}
export function remove(id) {
    return axios.post("/serve/delete", {id})
}
