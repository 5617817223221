export const COLUMNS = [
    {
        title: '状态',
        colKey: 'isShow',
        width: 100
    },
    {
        title: '名称',
        colKey: 'name',
    },
    {
        title: '描述',
        colKey: 'description',
    },
    {
        title: '排序',
        colKey: 'orderNum',
    },

    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
export const RULES = {
    name: [
        { required: true, message: '名称必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    description: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
