import axios from "axios";

export function fetchActivityPage(parms) {
    return axios.post("/activity/page", parms)
}
export function fetchActivityList(parms) {
    return axios.post("/activity/list", parms)
}
export function fetchActivityForm(id) {
    return axios.get("/activity/form", {
        params: {id}
    })
}

export function saveActivity(entity) {
    return axios.post("/activity/save", entity)
}

export function recycleActivity(id) {
    return axios.post("/activity/recycle", {id})
}
export function recoverActivity(id) {
    return axios.post("/activity/recover", {id})
}
export function deleteActivity(id) {
    return axios.post("/activity/delete", {id})
}
export function cancelActivity(data) {
    return axios.post("/activity/cancel", data)
}
export function postponeActivity(data) {
    return axios.post("/activity/postpone", data)
}
export function genCode(id) {
    return axios.get("/activity/genCode", {
        params: {id}
    })
}
