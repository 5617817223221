export const COLUMNS = [
    {
        title: '状态',
        colKey: 'state',
        width: 130
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
export const RULES = {
    username: [
        { required: true, message: '名称必填', type: 'error' },
        { max: 30, message: '不能超过30个字符', type: 'error' },
    ],
    password: [
        { required: true, message: '密码必填', type: 'error' },
        { max: 30, message: '不能超过30个字符', type: 'error' },
    ],
    host: [
        { required: true, message: 'HOST必填', type: 'error' },
        { max: 30, message: '不能超过30个字符', type: 'error' },
    ],
    protocol: [
        { required: true, message: '邮件协议必填', type: 'error' },
        { max: 10, message: '不能超过10个字符', type: 'error' },
    ],
    defaultEncoding: [
        { required: true, message: '默认编码必填', type: 'error' },
        { max: 10, message: '不能超过10个字符', type: 'error' },
    ],
    description: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
