export const COLUMNS = [
    {
        title: '状态',
        colKey: 'state',
        align:'center',
        width: 90
    },
    {
        title: '姓名',
        colKey: 'name',
    },

    {
        title: '手机号',
        colKey: 'number',
    },
    {
        title: '公司名',
        colKey: 'companyName',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: 110,
    },
    {
        title: '',
        colKey: 'empty',
        width: 1,
    },
];
