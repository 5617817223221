import axios from "axios";

export function fetchClassifyPage(parms) {
    return axios.post("/productType/page", parms)
}
export function fetchClassifyList(parms) {
    return axios.post("/productType/list", parms)
}
export function fetchClassifyForm(id) {
    return axios.get("/productType/form", {
        params: {id}
    })
}
export function saveClassify(entity) {
    return axios.post("/productType/save", entity)
}
export function deleteClassify(id) {
    return axios.post("/productType/delete", {id})
}
