export const COLUMNS = [
    {
        title: '模块',
        colKey: 'modul',
    },
    {
        title: '类型',
        colKey: 'type',
    },
    {
        title: '描述',
        colKey: 'description',
    },
    {
        title: '操作用户',
        colKey: 'createdBy',
    },
    {
        title: '操作时间',
        colKey: 'createdTime',
    },
    {
        title: 'IP',
        colKey: 'ip',
    },
];
