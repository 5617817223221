export const COLUMNS = [
    {
        title: '名称',
        colKey: 'name',
    },
    {
        title: '备注',
        colKey: 'remark',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
export const RULES = {
    name: [
        { required: true, message: '名称必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    remark: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
