export const COLUMNS = [
    {
        title: '显示',
        colKey: 'show',
        align:'center',
        width: 60
    },
    {
        title: '名称',
        colKey: 'name',
    },

    {
        title: '分类',
        colKey: 'classifyName',
        width: 100
    },
    {
        title: '报名时间',
        colKey: 'applyDate',
    },
    {
        title: '会议时间',
        colKey: 'date',
    },
    {
        title: '地点',
        colKey: 'addr',
    },
    // {
    //     title: '通知',
    //     colKey: 'noticeTms',
    //     align:'center',
    //     width: 90
    // },
    {
        title: '状态',
        colKey: 'state',
        align:'center',
        width: 70
    },
    {
        title: '小程序码',
        colKey: 'codeSrc',
        width: 100,
    },
    {
        title: '排序',
        colKey: 'orderNum',
        align:'center',
        width: 60,
    },
    {
        title: '操作',
        colKey: 'operation',
        width: 110,
    },
];
export const RULES = {
    name: [
        { required: true, message: '名称必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    addr: [
        { required: true, message: '地点必填', type: 'error' },
        { max: 100, message: '不能超过50个字符', type: 'error' },
    ],
    classifyId: [
        { required: true, message: '分类必填', type: 'error' },
    ],
    cover: [
        { required: true, message: '封面图必填', type: 'error' },
    ],
    description: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
