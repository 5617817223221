export const COLUMNS = [
    {
        title: '状态',
        colKey: 'state',
        align:'center',
        width: 90
    },
    {
        title: '名称',
        colKey: 'name',
    },
    {
        title: '发布时间',
        colKey: 'publishTime',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
export const RULES = {
    name: [
        { required: true, message: '名称必填', type: 'error' },
        { max: 100, message: '不能超过50个字符', type: 'error' },
    ],
    cover: [
        { required: true, message: '封面图必填', type: 'error' },
    ],
    publishTime: [
        { required: true, message: '发布时间必填', type: 'error' },
    ],
    description: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
