import axios from "axios";

export function fetchClassifyPage(parms) {
    return axios.post("/caseClassify/page", parms)
}
export function fetchClassifyList(parms) {
    return axios.post("/caseClassify/list", parms)
}
export function fetchClassifyForm(id) {
    return axios.get("/caseClassify/form", {
        params: {id}
    })
}

export function saveClassify(entity) {
    return axios.post("/caseClassify/save", entity)
}

export function deleteClassify(id) {
    return axios.post("/caseClassify/delete", {id})
}
