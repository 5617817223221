export const MENU_TYPE_OPTIONS = [
    {value: 1, label: '目录'},
    {value: 2, label: '菜单'},
    {value: 3, label: '权限'}
]
export function getMenuTypeLabel(value:number){
    for(let i = 0; i< MENU_TYPE_OPTIONS.length; i++){
        if(MENU_TYPE_OPTIONS[i].value === value){
            return MENU_TYPE_OPTIONS[i].label;
        }
    }
}

export const MAIL_TYPE_OPTIONS = [
    {value: 1, label: '活动审核通过'},
    {value: 2, label: '活动审核未通过'},
    {value: 3, label: '活动提醒'},
    {value: 4, label: '活动取消'},
    {value: 5, label: '活动延期'},
    {value: 6, label: '资料下载'},
    {value: 7, label: '活动报名通过'},
]
export function getMeilTypeLabel(value:number){
    return getLabel(value, MAIL_TYPE_OPTIONS);
}

export const QUESTIONNAIRE_TYPE_OPTIONS = [
    {value: 1, label: '活动问卷'},
    {value: 2, label: '下载问卷'},
]
export function getQuestionnaireTypeLabel(value:number){
    return getLabel(value, MAIL_TYPE_OPTIONS);
}


function getLabel(value:number, array:any){
    for(let i = 0; i< array.length; i++){
        if(array[i].value === value){
            return array[i].label;
        }
    }
}

export const PRODUCT_TYPE_OPTIONS = [
    {value: 1, label: '冠脉介入'},
    {value: 2, label: '外周介入'},
    {value: 3, label: '血透通路'},
    {value: 4, label: '血透通路'},
    {value: 5, label: '血透通路'},
]
