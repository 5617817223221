import axios from "axios";

export function fetchSenderPage(parms) {
    return axios.post("/mailSender/page", parms)
}
export function fetchSenderList(parms) {
    return axios.post("/mailSender/list", parms)
}
export function fetchSenderForm(id) {
    return axios.get("/mailSender/form", {
        params: {id}
    })
}
export function deleteSender(id) {
    return axios.post("/mailSender/delete", {id})
}

export function saveSender(entity) {
    return axios.post("/mailSender/save", entity)
}
export function enableSender(id) {
    return axios.post("/mailSender/enable", {id})
}
