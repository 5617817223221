export const COLUMNS = [
    {
        title: '类型',
        colKey: 'type',
        width:200
    },
    {
        title: '邮件主题',
        colKey: 'subject',
    },
    {
        title: '收件人',
        colKey: 'receiver',
    },
    {
        title: '发件人',
        colKey: 'sender',
    },

    {
        title: '发送日期',
        colKey: 'createdTime',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
