import axios from "axios";

export function fetchMenuList(queryParam) {
    return axios.post("/menu/list", queryParam)
}
export function fetchMenuTree() {
    return axios.get("/menu/tree")
}
export function fetchMenuCatalogueTree() {
    return axios.get("/menu/tree/catalogue")
}
export function fetchMyMenuTree() {
    return axios.get("/menu/tree/my")
}
export function fetchMenuDetails(id) {
    return axios.get("/menu/details", {
        params: {id}
    })
}

export function saveMenu(entity) {
    return axios.post("/menu/save", entity)
}
