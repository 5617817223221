import axios from "axios";

export function fetchManagerList(parentId) {
    return axios.get("/manager/list", {
        params: {parentId}
    })
}
export function fetchManagerPage(parms) {
    return axios.post("/manager/page", parms)
}
export function fetchManagerDetails(id) {
    return axios.get("/manager/details", {
        params: {id}
    })
}

export function saveManager(entity) {
    return axios.post("/manager/save", entity)
}
export function fetchManagerForm(id) {
    return axios.get("/manager/form", {
        params: {id}
    })
}

export function deleteManager(id) {
    return axios.post("/manager/delete", {id})
}
export function fetchCurrentManager(){
    return axios.get("/manager/current");
}

export function upPassword(entity) {
    return axios.post("/manager/upPassword", entity)
}
