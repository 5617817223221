import axios from "axios";

export function fetchRecordPage(parms) {
    return axios.post("/mailRecord/page", parms)
}
export function fetchRecordForm(id) {
    return axios.get("/mailRecord/form", {
        params: {id}
    })
}
export function saveRecord(entity) {
    return axios.post("/mailRecord/save", entity)
}
