import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/tag/page", parms)
}
export function fetchList(parms) {
    return axios.post("/tag/list", parms)
}
export function fetchForm(id) {
    return axios.get("/tag/details", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/tag/save", entity)
}
export function remove(id) {
    return axios.post("/tag/delete", {id})
}
