export const COLUMNS = [
    {
        title: '名称',
        colKey: 'name',
    },
    {
        title: '手机号',
        colKey: 'number',
    },
    {
        title: '职位',
        colKey: 'job',
    },
    {
        title: '微信号',
        colKey: 'wxNumber',
    },
    {
        title: '公司',
        colKey: 'company',
    },
    {
        title: '固话',
        colKey: 'telephone',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
export const RULES = {
    name: [
        { required: true, message: '昵称必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    number: [
        { required: true, message: '手机号必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    company: [
        { required: true, message: '公司名必填', type: 'error' },
        { max: 60, message: '不能超过30个字符', type: 'error' },
    ],
    job: [
        { required: true, message: '职位必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ]
}
