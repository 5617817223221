import axios from "axios";

export function fetchClassifyPage(parms) {
    return axios.post("/productClassify/page", parms)
}
export function fetchClassifyList(parms) {
    return axios.post("/productClassify/list", parms)
}
export function fetchClassifyForm(id) {
    return axios.get("/productClassify/form", {
        params: {id}
    })
}

export function saveClassify(entity) {
    return axios.post("/productClassify/save", entity)
}

export function deleteClassify(id) {
    return axios.post("/productClassify/delete", {id})
}
