export const COLUMNS = [
    {
        title: '昵称',
        colKey: 'nickname',
    },
    {
        title: '用户名',
        colKey: 'username',
    },
    {
        title: '角色',
        colKey: 'roleNames',
    },
    {
        title: '备注',
        colKey: 'remark',
    },
    {
        title: '操作',
        colKey: 'operation',
        width: '10%',
    },
];
export const RULES = {
    nickname: [
        { required: true, message: '昵称必填', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    username: [
        { required: true, message: '用户名必填', type: 'error' },
        { min: 2, message: '长度不少于2位', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    password: [
        // { required: true, message: '密码必填', type: 'error' },
        { min: 6, message: '长度不少于6位', type: 'error' },
        { max: 40, message: '不能超过20个字符', type: 'error' },
    ],
    remark: [
        { max: 200, message: '不能超过100个字符', type: 'error' },
    ],
}
