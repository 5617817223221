import axios from "axios";

export function fetchActivityApplyPage(parms) {
    return axios.post("/activityApply/page", parms)
}
export function check(id) {
    return axios.post("/activityApply/check", {id})
}
export function reject(id) {
    return axios.post("/activityApply/reject", {id})
}
export function exportEecel(id) {
    let link = document.createElement("a");
    link.href = axios.defaults.baseURL + "/activityApply/export?activityId=" + id;
    //link.download = decodeURI("任务列表.xls"); // 下载后文件名
    document.body.appendChild(link);
    link.click(); // 点击下载
    document.body.removeChild(link); // 下载完成移除元素
}
