import { createRouter, createWebHistory } from 'vue-router'
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";

const solutionRoutes = [
    {
        path: '/solution/content',
        name: 'solutionContent',
        component: () => import('@/views/solution/content/SolutionContent.vue'),
    },
    {
        path: '/solution/classify',
        name: 'solutionClassify',
        component: () => import('@/views/solution/classify/SolutionClassify.vue'),
    },
]
const productRoutes = [
    {
        path: '/product/content',
        name: 'productContent',
        component: () => import('@/views/product/content/ProductContent.vue'),
    },
    {
        path: '/product/classify',
        name: 'productClassify',
        component: () => import('@/views/product/classify/ProductClassify.vue'),
    },
    {
        path: '/product/type',
        name: 'productType',
        component: () => import('@/views/product/type/ProductType.vue'),
    },
]
const serveRoutes = [
    {
        path: '/serve/information',
        name: 'informationContent',
        component: () => import('@/views/serve/information/InformationContent.vue'),
    },
    {
        path: '/serve/industry',
        name: 'industryContent',
        component: () => import('@/views/serve/industry/IndustryContent.vue'),
    },
]
const downloadRouters = [
    {
        path: '/download/pdf/content',
        name: 'pdfContent',
        component: () => import('@/views/download/pdf/PdfContent.vue'),
    },
    {
        path: '/download/pdf/classify',
        name: 'pdfClassify',
        component: () => import('@/views/download/pdf/PdfClassify.vue'),
    },
    {
        path: '/download/video/content',
        name: 'videoContent',
        component: () => import('@/views/download/video/VideoContent.vue'),
    },
    {
        path: '/download/video/classify',
        name: 'videoClassify',
        component: () => import('@/views/download/video/VideoClassify.vue'),
    },
]
const salesman= [
    {
        path: '/salesman',
        name: 'Salesman',
        component: () => import('@/views/salesman/Salesman.vue'),
    },
]
const tag= [
    {
        path: '/tag',
        name: 'Tag',
        component: () => import('@/views/tag/Tag.vue'),
    },
]
const searchKey= [
    {
        path: '/searchkey',
        name: 'SearchKey',
        component: () => import('@/views/search-key/SearchKey.vue'),
    },
]
const questioinnaire= [
    {
        path: '/questionnaire',
        name: 'Questionnaire',
        component: () => import('@/views/questionnaire/Questionnaire.vue'),
    },
]
const user= [
    {
        path: '/user',
        name: 'User',
        component: () => import('@/views/user/User.vue'),
    },
]
const visit= [
    {
        path: '/visit',
        name: 'Visit',
        component: () => import('@/views/visit/Visit.vue'),
    },
]
const statistics = [
    {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('@/views/statistics/Statistics.vue'),
    },
]
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        redirect: '/my',
        component: Home,
        children: [
            ...solutionRoutes,
            ...productRoutes,
            ...serveRoutes,
            ...downloadRouters,
            ...salesman,
            ...tag,
            ...searchKey,
            ...questioinnaire,
            ...user,
            ...visit,
            ...statistics,
            {
                path: '/my',
                name: 'My',
                component: () => import('@/views/my/My.vue'),
            },
            {
                path: '/building/:code',
                name: 'Building',
                component: () => import('@/views/building/Building.vue'),
            },
            {
                path: '/recycle',
                name: 'Recycle',
                component: () => import('@/views/recycle/Recycle.vue'),
            },
            {
                path: '/case/classify',
                name: 'caseClassify',
                component: () => import('@/views/case/classify/CaseClassify.vue'),
            },
            {
                path: '/case/content',
                name: 'caseContent',
                component: () => import('@/views/case/content/CaseContent.vue'),
            },
            {
                path: '/mail/sender',
                name: 'mailSender',
                component: () => import('@/views/mail/sender/MailSender.vue'),
            },
            {
                path: '/mail/template',
                name: 'mailTemplate',
                component: () => import('@/views/mail/template/MailTemplate.vue'),
            },
            {
                path: '/mail/record',
                name: 'mailRecord',
                component: () => import('@/views/mail/record/MailRecord.vue'),
            },
            {
                path: '/activity/calssify',
                name: 'activityCalssify',
                component: () => import('@/views/activity/classify/ActivityClassify.vue'),
            },
            {
                path: '/activity/content',
                name: 'activityContent',
                component: () => import('@/views/activity/content/ActivityContent.vue'),
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('@/views/news/News.vue'),
            },
            {
                path: '/log',
                name: 'Log',
                component: () => import('@/views/sys/log/Log.vue'),
            },
            {
                path: '/manager',
                name: 'manager',
                component: () => import('@/views/sys/manager/Manager.vue'),
            },
            {
                path: '/role',
                name: 'role',
                component: () => import('@/views/sys/role/Role.vue'),
            },
            {
                path: '/menu',
                name: 'menu',
                component: () => import('@/views/sys/menu/Menu.vue'),
            }
        ]
    }
]

const router = createRouter({
    mode:'history',
    base: process.env.BASE_URL,
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router
