import axios from "axios";

export function fetchTemplatePage(parms) {
    return axios.post("/mailTemplate/page", parms)
}
export function fetchTemplateList(parms) {
    return axios.post("/mailTemplate/list", parms)
}
export function fetchTemplateForm(id) {
    return axios.get("/mailTemplate/form", {
        params: {id}
    })
}
export function deleteTemplate(id) {
    return axios.post("/mailTemplate/delete", {id})
}

export function saveTemplate(entity) {
    return axios.post("/mailTemplate/save", entity)
}
export function enableTemplate(id) {
    return axios.post("/mailTemplate/enable", {id})
}
export function disableTemplate(id) {
    return axios.post("/mailTemplate/disable", {id})
}
