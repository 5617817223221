import axios from "axios";

export function fetchPage(parms) {
    return axios.post("/questionnaire/page", parms)
}
export function fetchForm(id) {
    return axios.get("/questionnaire/details", {
        params: {id}
    })
}
export function save(entity) {
    return axios.post("/questionnaire/save", entity)
}
export function remove(id) {
    return axios.post("/questionnaire/delete", {id})
}

export function genCode(id) {
    return axios.get("/questionnaire/genCode", {
        params: {id}
    })
}